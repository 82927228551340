/** @format */

import { DatePipe, TitleCasePipe } from "@angular/common"
import { Component, EventEmitter, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { ErrorHandlerService } from "src/app/services/errorHandler/error-handler.service"

import { LogsheetService } from "src/app/services/logsheet/logsheet.service"
import { DialogService } from "src/app/services/modal/dialog.service"
import { ToastService } from "src/app/services/toast/toast.service"

@Component({
	selector: 'app-unit-log',
	templateUrl: './unit-log.component.html',
	styleUrls: ['./unit-log.component.css'],
	host: { class: 'unit-log-component' },
	providers: [TitleCasePipe],
})
export class UnitLogComponent {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private titleCasePipe: TitleCasePipe,
		public toastService: ToastService,
		private logsheetService: LogsheetService,
		private datePipe: DatePipe,
		public dialogService: DialogService,
		public errorService: ErrorHandlerService,
	) {}

	@Output() onSelectDate: EventEmitter<any> = new EventEmitter<any>()

	startDate: string = ''
	currentUser: any
	role: any
	startDateFormatted: string = ''
	endDate: string = ''
	endDateFormatted: string = ''
	isExpired = false
	handleClose() {
		this.isExpired = false
		this.dialogService.showModal = false
	}

	checkLogsheet(log: any) {
		if (this.checkExpire(log)) {
			this.showExpire(log)
		} else {
			this.logsheetService
				.retrieveActualLogsheet(log.logSheetId)
				.subscribe({
					next: (logsheet: any) => {
						if (logsheet.status !== 'inProgress' || logsheet.logSheetTemplated.scheduleType.toLowerCase() === "ondemand") {
							this.router.navigate(
								[
									'/home/overview/unit',
									logsheet.logSheetTemplateId,
									'log-sheet-form',
								],
								{ state: { logSheet: log } },
							)
						} else {
							if (
								log.currentUser === this.currentUser ||
								this.role === 'Super Admin'
							) {
								this.router.navigate(
									[
										'/home/overview/unit',
										logsheet.logSheetTemplateId,
										'log-sheet-form',
									],
									{ state: { logSheet: log } },
								)
							} else {
								this.showInProgressToast()
							}
						}
					},
					error: (err) => {
						console.error(err)
					},
				})
		}
	}

	resetLogsheetStatus(log: any) {
		this.logsheetService
			.updateLogSheetStatus(log.logSheetId, {
				status: 'available',
				currentUser: '0',
			})
			.subscribe({
				next: (res) => {
					this.retrieveLogs(this.id)
				},
				error: (err) => {
					this.errorService.handleError(
						err,
						'Could not update logsheet status',
					)
				},
			})
	}

	showExpire(log: any) {
		if (log.adminModificationIsExpired && log.status === 'executed') {
			this.isExpired = true
		}
	}
	checkExpire(log: any) {
		return log.adminModificationIsExpired && log.status === 'executed'
	}

	showInProgressToast() {
		this.toastService.toastText = 'This Logsheet is currently in progress!'
		this.toastService.isError = true
		this.toastService.showToast = true
		setTimeout(() => {
			this.toastService.showToast = false
		}, 5000)
	}

	setStartDate() {
		if (!this.startDate) {
			this.startDateFormatted = ''
		} else {
			this.startDateFormatted = new Date(
				this.startDate.replace(/-/g, '/'),
			).toLocaleDateString('en-us', {
				year: '2-digit',
				month: 'short',
				day: 'numeric',
			})
		}

		if (this.endDate && new Date(this.startDate) < new Date(this.endDate)) {
			this.retrieveLogs(this.id, true)
			this.filteredLogs = this.logs
		}
		if (this.endDate && new Date(this.startDate) > new Date(this.endDate)) {
			this.filterErrorMessage =
				'Please select a start date that is before the end date'
		} else {
			this.filterErrorMessage = ''
		}
	}
	setEndDate() {
		if (this.startDate.length < 1) {
			this.filterErrorMessage = 'Please select a start date first'
			this.startDate = ''
			return
		}

		this.filterErrorMessage = ''
		this.endDateFormatted = new Date(
			this.endDate.replace(/-/g, '/'),
		).toLocaleDateString('en-us', {
			year: '2-digit',
			month: 'short',
			day: 'numeric',
		})

		this.retrieveLogs(this.id, true)
	}

	filterLogsheetsByDateRange(
		logsheets: any[],
		startDate: Date,
		endDate: Date,
	) {
		return logsheets.filter((logsheet) => {
			const itemDate = new Date(logsheet.logSheetTemplated.scheduleStart)
			return itemDate >= startDate && itemDate <= endDate
		})
	}

	getStatusColor(status: string) {
		switch (status) {
			case 'inProgress':
				return 'inprogress'
			case 'executed':
				return 'executed'
			case 'overdue':
				return 'overdue'
			case 'available':
				return 'available'
			case 'Upcoming':
				return 'upcoming'
			case 'Incomplete':
				return 'incomplete'
			default:
				return 'status-none'
		}
	}

	convertToTitleCase(status: string) {
		return status.replace(/([A-Z])/g, ' $1').trim()
	}

	currentPage: number = 1
	pageSize = 8
	totalPages = 10
	loopArray = new Array(this.pageSize).fill(0).map((_, index) => index + 1)
	currentIncrement = this.pageSize
	selectedPage = {
		backgroundColor: '#0F2642',
		color: 'white',
		borderRadius: '4px',
	}
	logs: any[] = []
	logSheetTemplateName: string = history?.state['0'] ?? ''
	id: number = 0
	filteredLogs: undefined | any[]
	filterErrorMessage: string = ''
	displayOverlay: boolean = false
	toggleOverlay() {
		this.displayOverlay = !this.displayOverlay
	}

	ngOnInit() {
		this.currentUser = localStorage.getItem('email')

		this.role = localStorage.getItem('role')
		this.route.params.subscribe((params) => {
			this.id = params['id']
			this.filteredLogs = undefined
			this.startDate = ''
			this.endDate = ''
			this.retrieveLogs(this.id)
		})
	}
	capitalize(string: string) {
		return this.titleCasePipe.transform(string)
	}
	stringToDate(date: string) {
		return this.datePipe.transform(date, 'MMM dd, yyyy')
	}
	stringToTime(date: string) {
		return this.datePipe.transform(date, 'h:mma')
	}
	retrieveLogs(id: number, reset?: boolean) {
		this.logSheetTemplateName = history?.state['0']
		if (reset !== true) {
			this.currentPage = 1
		}
		const pageNumber = this.currentPage
		const pageSize = this.pageSize
		const startDate = this.startDate
		const endDate = this.endDate
		const item = {
			id,
			pageSize,
			pageNumber,
			startDate,
			endDate,
		}

		this.logsheetService.retrieveLogSheetByRelativeId(item).subscribe({
			next: (res) => {
				this.logs = res

				this.totalPages = res[0]?.totalPages || 1
				this.loopArray = new Array(this.totalPages)
					.fill(0)
					.map((_, index) => index + 1)
				if (startDate && endDate) {
					this.filteredLogs = res
				}
			},
		})
	}

	incrementArray(arr: any, increment: any) {
		return arr.map((item: any) => item + increment)
	}

	decrementArray(arr: any, decrement: any) {
		return arr.map((item: any) => item - decrement)
	}

	shiftPageRight() {
		const incrementedArray = this.incrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = incrementedArray
	}

	shiftPageLeft() {
		const decrementArray = this.decrementArray(
			this.loopArray,
			this.currentIncrement,
		)
		this.loopArray = decrementArray
	}

	setCurrentPage(page: any) {
		this.currentPage = page
		this.retrieveLogs(this.id, true)
	}
	performIncrement() {
		if (this.currentPage === this.totalPages) return
		const result = this.currentPage + 1
		this.setCurrentPage(result)
		if (this.currentPage > this.loopArray[this.loopArray.length - 1]) {
			this.shiftPageRight()
		}
	}

	performDecrement() {
		if (this.currentPage === 1) return
		const result = this.currentPage - 1
		this.setCurrentPage(result)
		if (this.currentPage < this.loopArray[0]) {
			this.shiftPageLeft()
		}
	}

	emptyStateLogs = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'There are no cards are attached to this logsheet',
	}

	emptyFilteredLogs = {
		imgPath: '../../assets/logsheet.svg',
		message1: 'There are no cards that match this criteria',
	}
}
