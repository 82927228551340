<div class="login-wrapper">
    <form class='login' (ngSubmit)="login()">
        <div class="login-header">
            <img src="../../../assets/logo-white.svg" class="login-header-img" alt="" />
            <span class="login-header-divider"></span>
            <span class="login-header-text">Lightyear</span>
        </div>

        <div>
            <p class="login-text">Enter the information below to login.</p>
        </div>

        <div [ngClass]="{'login-success': model.success && model.message, 'login-error': !model.success}">
            <p *ngIf="model.attempts">Attempts Remaining: {{ model.attempts }}</p>
            <p *ngIf="model.attempts">
                <strong>Warning: </strong>After <strong>5</strong> consecutive failed login attempts, your account will be locked.
            </p>
            <p *ngIf="!model.attempts" class="error-msg">{{ model.message }}</p>
        </div>
        
        <div *ngIf="showExpire" class="login-error">
            <p>Your session has expired due to inactivity. Please sign in below to resume</p>
        </div>

        <div class="email-inputfield">
            <div class="email-text">Email</div>
            <div class="email-input">
                <input type="text" name="email" [(ngModel)]="model.username" placeholder="Email Address" autocomplete="off">
            </div>
        </div>
        <div class="password-inputfield">
            <div class="password-text">Password</div>
            <div class="password-input">
                <input type="password" name="password" [(ngModel)]="model.password" placeholder="Password" autocomplete="off" id="">
            </div>
        </div>
        <div class="user-options">
            <span class="checkbox"><input type="checkbox" name="remember-user" id="">Remember me</span>
            <span class="forget-pasword"><button type="button" (click)="handleForgetPassword()">Forget Password</button></span>
        </div>
        <div class="login-btn">
            <button type="submit">Login</button>
        </div>        
        <div class="footer">
            <p class="login-text">Version: {{ model.version }}</p>
            <p class="login-text">Copyright © 2024. Jamaica Public Service Company Limited. All rights reserved. | <small><a target="_blank" href="https://www.smsja.com/">Developed by Smart Mobile Solutions Jamaica Limited</a></small></p>
        </div>
    </form>
</div>